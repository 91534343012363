<template>
    <div :id="blok.anchor" class="ui-simple-title" data-test="ui-simple-title">
        <component
            :is="blok.headingType ? blok.headingType : 'h2'"
            :style="componentStyles"
            :class="{
                'has-divider': blok.font[0]?.underline === EUnderlineTypes.DIVIDER,
                'has-underline-border': blok.font[0]?.underline === EUnderlineTypes.BORDER,
            }"
        >
            <span :style="buildTextStyles(blok.font?.[0] as IComponentFont)">{{ blok.text }}</span>
        </component>
    </div>
</template>

<script setup lang="ts">
import type { ICoreSimpleTitle } from '~ui/types/components/CoreSimpleTitle';
import type { IComponentFont } from '~ui/types/ComponentStyles';
import { EUnderlineTypes } from '~ui/types/enums/ComponentStyle';
import { buildTextStyles } from '~ui/utils/styleBuilder';
import { useIsMobileView } from '~ui/composables/isMobileView';
import screens from '#tailwind-config/theme/screens';

const { blok } = defineProps<{ blok: ICoreSimpleTitle }>();
const { isMobileView } = useIsMobileView(screens.sm);

const componentStyles = computed(() => ({
    ...buildFonts(blok.font?.[0] as IComponentFont),
    'text-align': isMobileView.value ? blok.mobileAlignment : blok.font?.[0]?.alignment,
    'line-height': blok.font?.[0]?.fontSize && blok.font?.[0]?.fontSize < 100 ? `${blok.font?.[0]?.fontSize}%` : null,
}));
</script>

<style scoped lang="postcss">
.ui-simple-title {
    h1 {
        @apply text-4xl;
    }
    h2 {
        @apply text-4xl;
    }
    h3 {
        @apply text-3xl;
    }
    h4 {
        @apply text-2xl;
    }
    h5 {
        @apply text-xl;
    }
    h6 {
        @apply text-lg;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply leading-[normal];
    }
    .has-underline-border {
        @apply border-b-[1px];
        border-color: var(--font-color, #000);
    }
    .has-divider {
        @apply relative;
        &:before {
            @apply content-[""] h-[3px] w-full absolute bottom-px left-0;
            background-color: var(--font-color, #000);
        }
    }
}
</style>
